import React from "react"
import { Link, graphql, PageProps } from "gatsby";

import Layout from "../components/layout"
import Seo from "../components/seo"
import { h1, date } from "../base/typography.css";
import linkList from "../components/linkList.css";
import { references } from "../components/references/references.css";

type BlogPostBySlugQuery = {
  site: {
    siteMetadata: {
      title: string;
    }
  };
  markdownRemark: {
    id: number;
    excerpt: string;
    html: string;
    frontmatter: {
      title: string;
      date: string;
      description: string;
      references: string[];
    }
  }
}

type BlogPostProps = PageProps<BlogPostBySlugQuery, BlogPageContext>;

const BlogPostTemplate = ({ data, pageContext, location }: BlogPostProps) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata.title
  const { previous, next } = pageContext

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article>
        <header>
          <h1 className={h1}>
            {post.frontmatter.title}
          </h1>
          <time className={date}>
            {post.frontmatter.date}
          </time>
        </header>
        <section dangerouslySetInnerHTML={{ __html: post.html }} />
        {post.frontmatter.references && (<details className={references}>
          <summary>References</summary>
          <ul>
            {post.frontmatter.references.map(url => (
              <li key={url}><a href={url} target="_blank" rel="noopener noreferrer">{url}</a> </li>
            ))}
          </ul>
        </details>)}
      </article>

      <nav>
        <ul className={linkList}>
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        references
      }
    }
  }
`
